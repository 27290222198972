import { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { TabGroup } from '@80db/core-ui';

import { ChangePassword } from '../ChangePassword';
import { EditProfile } from '../EditProfile';

export const UserAccount = () => {
    const { tabIndex } = useParams();
    const [activeTab, setActiveTab] = useState(parseInt(tabIndex || '0'));

    useEffect(() => {
        setActiveTab(parseInt(tabIndex || '0'));
    }, [tabIndex]);

    return (
        <div className="p-4 md:p-8 lg:px-12 lg:py-8 flex-1 bg-slate-200">
            <div className="mb-4">
                <h1 className="flex font-medium text-blue-500 text-2xl md:text-3xl lg:text-4xl break-words ">My Account</h1>
            </div>
            <div className="max-w-4xl">
                <TabGroup direction={TabGroup.direction.HORIZONTAL}>
                    <TabGroup.TabList numTabs={2} activeTab={activeTab} setActiveTab={setActiveTab}>
                        <TabGroup.Tab activeTab={activeTab} setActiveTab={setActiveTab} index={0} className="nav-tab" activeClassName="nav-tab--active">
                            <span data-testid="Profile" onClick={() => setActiveTab(0)}>
                                Profile
                            </span>
                        </TabGroup.Tab>
                        <TabGroup.Tab activeTab={activeTab} setActiveTab={setActiveTab} index={1} className="nav-tab" activeClassName="nav-tab--active">
                            <span data-testid="Profile" onClick={() => setActiveTab(1)}>
                                Password
                            </span>
                        </TabGroup.Tab>
                    </TabGroup.TabList>
                    <TabGroup.TabPanel
                        activeTab={activeTab}
                        index={0}
                        className="transition-all transform"
                        activeClassName="opacity-100 duration-500 translate-x-0"
                        inactiveClassName="absolute left-0 right-0 top-12 opacity-0 -translate-x-2 h-0 overflow-hidden"
                    >
                        <EditProfile />
                    </TabGroup.TabPanel>
                    <TabGroup.TabPanel
                        activeTab={activeTab}
                        index={1}
                        className="transition-all transform"
                        activeClassName="opacity-100 duration-500 translate-x-0"
                        inactiveClassName="absolute left-0 right-0 top-12 opacity-0 -translate-x-2 h-0 overflow-hidden"
                    >
                        <ChangePassword />
                    </TabGroup.TabPanel>
                </TabGroup>
            </div>
        </div>
    );
};
