import { createSearchParams, useNavigate } from 'react-router-dom';
import { Button, Table, useCreateColumnHelper } from '@80db/core-ui';
import { Can } from '../../../Auth/Can';
import { useLocalStorage } from '../../../hooks';
import { AccessType, Seminar, User } from '../../../types';
import { SectionHeading } from '../../SectionHeading';
import { DisplayDate } from '../../Shared';

export const PastOrStartedSeminars = ({ seminars }: { seminars: Seminar[] }) => {
    const columnHelper = useCreateColumnHelper<Seminar>();
    const [storedValue] = useLocalStorage('_user', null);
    const access = (storedValue as User)?.access || 0;
    const navigate = useNavigate();

    const columns = [
        columnHelper.accessor((row) => row.title, {
            id: 'title',
            cell: (info) => <h3 className="line-clamp-2">{info.getValue()}</h3>,
            header: () => <span>Title</span>
        }),
        columnHelper.accessor((row) => row.startDate, {
            id: 'startDate',
            cell: (info) => <DisplayDate date={info.getValue()} />,
            header: () => <span>Date</span>
        }),
        columnHelper.display({
            id: 'watch',
            cell: ({ row }) => {
                const { id, subscriptionId } = row.original;
                return (
                    <div className="flex items-center md:items-start justify-end gap-2 md:gap-4 mt-2 md:relative z-20">
                        <Can I="moderate" a="Seminar">
                            <Button
                                classNames="relative bg-gradient-to-r from-teal-500 to-teal-600 text-white py-2 rounded-lg flex items-center border-none focus:outline-none transition-all group"
                                onClick={() => {
                                    navigate(
                                        {
                                            pathname: '/moderate',
                                            search: createSearchParams({
                                                Id: id
                                            }).toString()
                                        },
                                        { state: { subscriptionId } }
                                    );
                                }}
                            >
                                Moderate
                            </Button>
                        </Can>
                        <Can I="broadcast" a="Seminar">
                            <Button
                                classNames="relative bg-gradient-to-r from-teal-500 to-teal-600 text-white py-2 rounded-lg flex items-center border-none focus:outline-none transition-all group"
                                onClick={() => {
                                    navigate(
                                        {
                                            pathname: '/broadcast',
                                            search: createSearchParams({
                                                Id: id
                                            }).toString()
                                        },
                                        { state: { subscriptionId } }
                                    );
                                }}
                            >
                                Broadcast
                            </Button>
                        </Can>
                    </div>
                );
            }
        })
    ];

    return (
        <div className="my-8 relative">
            <div className="rounded-tl-xl rounded-br-3xl bg-white flex flex-col relative p-4 w-full">
                <section className="flex flex-col pl-6">
                    <SectionHeading title="Started events" />
                </section>
                <div className="py-6 my-6">
                    <Table isHeader={true} columns={columns} data={seminars} classNames="table-responsive table-striped w-full mt-2 seminars-table"></Table>
                </div>
                <div className="absolute bottom-0 mb-4 pl-6">
                    {access >= AccessType.Moderator && (
                        <Button
                            classNames="relative bg-gradient-to-r from-teal-500 to-teal-600 text-white py-2 rounded-lg flex items-center border-none focus:outline-none transition-all group"
                            data-testid="btnModerate"
                            onClick={() => {}}
                        >
                            <span className="mx-2">All previous events</span>
                            <svg fill="currentColor" viewBox="0 0 16 16" height="1.2em" width="1.2em" className="mr-0.5" >
                                <path fillRule="evenodd"
                                d="M15 8a.5.5 0 00-.5-.5H2.707l3.147-3.146a.5.5 0 00-.708-.708l-4 4a.5.5 0 000 .708l4 4a.5.5 0 00.708-.708L2.707 8.5H14.5a.5.5 0 00.5-.5z"/>
                            </svg>
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};