import { Suspense } from 'react';

import { Navigate, Outlet, useOutlet } from 'react-router-dom';

import { Layout } from '@80db/core-ui';
import { Logo } from '@80db/core-ui';

import { useAuth } from '../../Auth';

export const HomeLayout = () => {
    const { userAccessToken } = useAuth() || {};
    const outlet = useOutlet();

    if (userAccessToken) {
        return <Navigate to="/dashboard" />;
    }

    if (!outlet) {
        return <Navigate to="/login" />;
    }

    return (
        <Layout>
            <Suspense fallback="loading..">
                <div className="h-screen flex items-center justify-center bg-blue-600">
                    <div className="flex flex-col w-96 p-12 bg-white rounded-tl-6xl rounded-br-6xl">
                        <Logo classNames="w-14 h-14 fill-blue-500" />
                        <h1 className="text-2xl md:text-3xl lg:text-4xl break-words my-6 text-neutral-500">Login</h1>
                        <Outlet />
                    </div>
                </div>
            </Suspense>
        </Layout>
    );
};
